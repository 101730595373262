﻿.dimmer {
  z-index: 2;
  filter: alpha(opacity= 85);
  opacity: .85;
  background-color: #ccc;
  width: 100%;
  height: 8000px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.processingdimmer {
  z-index: 4;
  filter: alpha(opacity= 85);
  opacity: .85;
  background-color: #ccc;
  width: 100%;
  height: 8000px;
  margin-bottom: -5000px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.processingImg {
  z-index: 1;
  width: 200px;
  height: 200px;
  margin: -100px 0 0 -100px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes uil-default-anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.uil-default-css > div:first-of-type {
  animation: 1s linear -1s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(2) {
  animation: 1s linear -.9s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(3) {
  animation: 1s linear -.8s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(4) {
  animation: 1s linear -.7s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(5) {
  animation: 1s linear -.6s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(6) {
  animation: 1s linear -.5s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(7) {
  animation: 1s linear -.4s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(8) {
  animation: 1s linear -.3s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(9) {
  animation: 1s linear -.2s infinite uil-default-anim;
}

.uil-default-css > div:nth-of-type(10) {
  animation: 1s linear -.1s infinite uil-default-anim;
}

.uil-default-css {
  background: none;
  width: 200px;
  height: 200px;
  position: relative;
}

/*# sourceMappingURL=freeziUI.css.map */
