﻿.dimmer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 8000px;
    z-index: 2;
    display: none;
    background-color: #CCC;
    filter: alpha(opacity = 85);
    opacity:.85;
}
.processingdimmer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 8000px;
    margin-bottom:-5000px;
    z-index: 4;
    display: none;
    background-color: #CCC;
    filter: alpha(opacity = 85);
    opacity:.85;
}
.processingImg {
    display: none;
    position: absolute; 
    margin:-100px 0 0 -100px; 
    top:50% ; 
    left:50%; 
    height:200px; 
    width:200px;
    z-index:1;
}

@-webkit-keyframes uil-default-anim {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes uil-default-anim {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.uil-default-css > div:nth-of-type(1) {
    -webkit-animation: uil-default-anim 1s linear infinite;
    animation: uil-default-anim 1s linear infinite;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(2) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(3) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(4) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(5) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(6) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(7) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(8) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(9) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}

    .uil-default-css > div:nth-of-type(10) {
        -webkit-animation: uil-default-anim 1s linear infinite;
        animation: uil-default-anim 1s linear infinite;
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
    }

.uil-default-css {
    position: relative;
    background: none;
    width: 200px;
    height: 200px;
}